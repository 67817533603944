.footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;

    .footerItem{
        font-size: 3rem;
        margin-left: 1rem;
        margin-right: 1rem;

        &:hover{
            color: rgb(223, 74, 10);
            cursor: pointer;
        }
    }
}