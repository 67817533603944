.main{
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* mobile viewport bug fix */
    @media screen and (max-width: 500px){
        height: -webkit-fill-available;
    }

    
        .content{
            width: 100%;
            height: 100%;
            border-top-left-radius: 9rem 40%;
            background-color: #000000;
            border: 5px solid white;
            border-style: none none none dashed;
            transition: 0.4s;
            position: relative;

            &.mobile{
                border-top-left-radius: 0;
            }

            .page{
                width: 56%;
                padding-left: 4%;
                padding-right: 4%;
                flex-direction: column;
                margin-top: 2rem;

                @media screen and (max-width: 1000px){
                    width: initial;
                    display: flex;
                    flex-grow: 1;
                    margin-top: initial;
                }

                .title{
                    width:100%;
                    text-align: center;
                    font-size: 2.5rem;

                    @media screen and (max-width: 800px){
                        font-size: 7vw;
                    }
                }
            }
        }

    .imgContentRight{
        height: 100%;
        width: 36%;
        position: absolute;
        right: 0;
        top: 0;

        @media screen and (max-width: 1000px){
            display: none;
        }
    }

    .imgContentBottom{
        display: none;

        @media screen and (max-width: 1000px){
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
            width: 40%;
        }
    }
}