.containerCheckbox{
    margin-top: 1%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inputsCheckBox{
    width: 80%;
    text-align: initial;
    padding-bottom: 1.5rem;
    margin: 0 auto;
  }
  
  .mainCheckbox {
    display: flex;
    position: relative;
    //margin-bottom: 15px;
    cursor: pointer;
    width: 71%;
    align-items: center;
  
    .acorduri_link{
      color : #fff;
      a{
        text-decoration: underline;
      }
    }
  }
  
  /* Hide the default checkbox */
  input[type="checkbox"] {
    visibility: hidden;
    position: absolute;
  }
  
  /* Creating a custom checkbox
  based on demand */
  .checkCustom {
    top: 0;
    left: 0;
    height: 12px;
    width: 12px;
    border: 2px solid rgb(202, 202, 202);
    background: #fff;
  }
  
  .checkboxLabel{
      text-align: left;
      display: block;
      font-size: 1rem;
      margin-left: 10px;
      display: flex;
      align-items: center;
      min-height: 10px;
      
      a{
          color: #fff
      }

      @media screen and (max-width: 500px) {
        font-size: 0.7rem;
    }
  }

  /* Checkmark to be shown in checkbox */
  /* It is not be shown when not checked */
  .checkCustom:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Display checkmark when checked */
  .main input[checked] ~ .checkCustom:after {
    display: block;
  }
  
  .main input[checked] ~ .checkCustom {
    background: #ff0000;
    border-color: #ff0000;
  }
  
  /* Styling the checkmark using webkit */
  /* Rotated the rectangle by 45 degree and
  showing only two border to make it look
  like a tickmark */
  .main .checkCustom:after {
    left: 4px;
    bottom: 8px;
    width: 4px;
    height: 10px;
    border: solid #fff;
    border-width: 0 4px 4px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);

    @media screen and (max-width: 500px) {
      left: 5px;
      bottom: 4px;
      width: 3px;
      height: 8px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
    }
  }   