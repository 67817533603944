body {
  margin: 0;
  font-family: Poppins;
  color: #ffffff;
  height: 100vh;
  height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

/* width */
::-webkit-scrollbar {
    width: 20px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: red; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000; 
  }

  //select
  .select{
    width: 70%;
    display: flex;
    justify-content: center;
    .labelName{
      margin-top: 2%;
      line-height: 1.5;
    }
    .error{
      color:#ff0000;
      margin-top: -0.2%;
      font-size: 0.8rem;
      font-weight: bold;
  }
  .css-1pahdxg-control{
    box-shadow: 0 0 0 1px #000000 !important;
    border: 1px solid #000000 !important;
  }
  .css-1pahdxg-control:hover{
    box-shadow: 0 0 0 1px #000000 !important;
    border: 1px solid #000000 !important;
  }
}