#circle-loader-wrap {
    overflow: hidden;
    position: absolute;
    margin: 5px;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1) inset;
    background-color: #ffffff;
    border-radius: 200px;
    transform: rotate(180deg);
}

#circle-loader-wrap:after {
    content: '';
    position: absolute;
    left: 8px;
    top: 8px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

#circle-loader-wrap div {
    overflow: hidden;
    position: absolute;
    width: 50%;
    height: 100%;
}

#circle-loader-wrap .loader {
    position: absolute;
    left: 100%;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 1000px;
    background-color: red;
}

#circle-loader-wrap .left-wrap {
    left: 0;
}

#circle-loader-wrap .left-wrap .loader {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    transform-origin: 0 50% 0;
    animation: loading-left 5s infinite linear;
}

#circle-loader-wrap .right-wrap {
    left: 50%;
}

#circle-loader-wrap .right-wrap .loader {
    left: -100%;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    transform-origin: 100% 50% 0;
    animation: loading-right 5s infinite linear;
}

@keyframes loading-left {
    0% {
        transform: rotate(0deg);
    }
    25%, 100% {
        transform: rotate(180deg);
    }
}

@keyframes loading-right {
    0%, 25% {
        transform: rotate(0deg);
    }
    50%, 100% {
/*          the following is for the second half of the cicrle */
/*          180deg means one half of the cicle or 50% of the cicle */
/*          So, 1% is gonna be 180/50 = 3.6deg */
/*          If you want 68%, then you have 18% left for the second half of the circle */
/*          To get 18%: 18x3.6 = 64.8deg */
        transform: rotate(180deg);
/*          Note: The transformation will happen between 25% and 50% of the total time which is 5 seconds in this case; So, it's gonna take 1.25 seconds. */
/*          In other words, it will take the same amount of time as for the first half of the circle which will make the transformation in the second half appear to be slower because it has the same time to cover a much shorter distance */
/*          Between 50% and 100% nothing happens. */
/*          That's your "pause" in this animation although technically it's not a pause. */
    }
}