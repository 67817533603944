.home{
    position: relative;
}

.imgCook{
    width: 40%;
    bottom: 0;
    position: absolute;

    @media screen and (max-width: 1000px){
        width: 60%;
        margin-bottom: 20%;
    }
    @media screen and (max-width: 650px){
        margin-bottom: 6rem;
        width: 70%;
    }
}